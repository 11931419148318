import React, { useState, useEffect } from 'react';

function Projects() {
  const [projectComponents, setProjectComponents] = useState([]);

  const tableOfContentsStyle = {
    position: 'fixed',
    top: 50,
    right: 0,
    overflowY: 'auto',
    maxHeight: '100vh',
    width: '250px', 
    padding: '8px', 
    backgroundColor: '#black', 
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', 
    zIndex: 1000, 

  };

  useEffect(() => {
    const importProjects = async () => {
      const projectsContext = require.context('../components/ProjectList', false, /\.jsx$/);
      const projectModules = projectsContext.keys().map(projectsContext);
      const projectComponents = projectModules.map(module => module.default);
      setProjectComponents(projectComponents);
    };

    importProjects();
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '1', paddingLeft: '15px' }}>
        <h1>Projects</h1>
        <div>
          {projectComponents.map((Project, index) => (
            <div key={index} id={`project${index + 1}`}>
              <Project />
            </div>
          ))}
        </div>
      </div>
      <div style={{width: '250px', borderLeft: '1px solid white'}}></div>
      <div style={tableOfContentsStyle}>
        <h2>Table of Contents</h2>
        <ul>
          <li key={1}><a href={`#project1`} onClick={() => scrollToSection('project1')} style={{ color: 'white' }}>Contraption v2</a></li>
          <li key={2}><a href={`#project2`} onClick={() => scrollToSection('project2')} style={{ color: 'white' }}>wherecanibuybox.info</a></li>
          <li key={3}><a href={`#project3`} onClick={() => scrollToSection('project3')} style={{ color: 'white' }}>Keyboard Timing Auditor</a></li>
          <li key={3}><a href={`#project4`} onClick={() => scrollToSection('project4')} style={{ color: 'white' }}>Pomodoro Watch App</a></li>

        </ul>
      </div>
      
    </div>
  );
}

export default Projects;