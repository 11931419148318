import React from 'react';
import { Container, Typography, Link, Grid } from '@mui/material';

function Contact() {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Contact Info
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            You can contact me through the following channels:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Link href="mailto:shahitrilok@gmail.com" color="inherit" underline="always">
              Email: shahitrilok@gmail.com
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Link href="https://www.linkedin.com/in/trilok-s-93946452/" color="inherit" underline="always">
              LinkedIn Profile
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Link href="https://github.com/tril0k" color="inherit" underline="always">
              GitHub Profile
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Contact;