import React from 'react';
function Project3() {
  return (
    <div>
      <h2>Pomodoro Watch App</h2>
      <img src={require('../../Images/pomodoro.PNG')} alt="wearos watch app" width="300" height="300" className="tooltip" />
      <p>Pomodoro Watch App implements the pomodoro time management method. The app sends an alert and vibration for 25 and 5 minute increments. It was designed for my pixel 2 watch using Kotlin for wear os 4.
      </p>
    </div>
  );
}

export default Project3;