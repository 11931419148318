import React from 'react';

function Project2() {
  return (
    <div>
      <h2>wherecanibuybox.info</h2>
      <img src={require('../../Images/wherecanibuybox.PNG')} alt="table snapshot" width="300" height="200"/>
      <p>
        <a href='https://wherecanibuybox.info' style={{ color: 'white'}}>wherecanibuybox.info</a> is a website that lists the most popular smash digital controllers in order to aid users in purchasing one.
        The table holds various data that can be sorted or filtered by tag.
        The site was created in React with Material UI.
      </p>
    </div>
  );
}

export default Project2;