import React from 'react';
function Project3() {
  return (
    <div>
      <h2>Keyboard Timing Auditor</h2>
      <img src={require('../../Images/keyboardAuditor.PNG')} alt="box controller" width="300" height="200" className="tooltip" />
      <p>Keyboard Timing Auditor is a program that tracks time between specific keyboard presses and sequences.
        Feedback is then given to the user to analyze and adjust their key press timings.
      </p>
    </div>
  );
}

export default Project3;