import React, { useEffect } from 'react';
import { Container, Typography, Grid, Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import Image1 from '../Images/contraption2.PNG';
import Image2 from '../Images/wherecanibuybox.PNG';
import Image3 from '../Images/pomodoro.PNG';
import { Link } from 'react-router-dom';

function Home() {
  const heroContentStyle = {
    padding: '64px 0',
  };

  const cardGridStyle = {
    paddingBottom: '32px',
  };

  const cardStyle = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  };

  const cardMediaStyle = {
    paddingTop: '56.25%', // 16:9
  };

  const cardContentStyle = {
    flexGrow: 1,
  };

  const projects = [
    { id: 'project1', title: 'Contraption v2', description: 'hybrid controller combining digital and analog', imageUrl: Image1, link: '/projects' },
    { id: 'project2',title: 'wherecanibuybox.info', description: 'website listing most popular smash box controllers and features', imageUrl: Image2 , link: '/projects' },
    { id: 'project4',title: 'Pomodoro Watch App', description: 'wear OS watch app that implements the Pomodoro time management method', imageUrl: Image3 , link: '/projects' },
  ];


  useEffect(() => {
    const hash = window.location.hash.substr(1);
    const element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  
  const scrollToProject = (id) => {
    setTimeout(() => { 
      const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    }, "200");
    
  };


  return (
    <React.Fragment>
      <div style={heroContentStyle}>
        <Container maxWidth="sm">
          <Typography variant="h4" align="center" color="textSecondary" paragraph>
            Welcome to my personal website. Here you can find information about me and some of my projects.
          </Typography>
        </Container>
      </div>
      <Container style={cardGridStyle} maxWidth="md">
        <Grid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Link to={project.link} style={{ textDecoration: 'none' }} onClick={() => scrollToProject(project.id)}>
                <Card style={cardStyle}>
                  <CardActionArea>
                    <CardMedia
                      style={cardMediaStyle}
                      image={project.imageUrl}
                      title={project.title}
                    />
                    <CardContent style={cardContentStyle}>
                      <Typography gutterBottom variant="h6" component="h2">
                        {project.title}
                      </Typography>
                      <Typography>
                        {project.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Home;