import React, { useState } from 'react';
import { Grid, Button, Card, CardMedia, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Image1 from '../../Images/contraption2.PNG';
import Image2 from '../../Images/contraption15.jpg';
import Image3 from '../../Images/contraption1.jpg';

const images = [
  { src: Image1, title: 'Contraption v2' },
  { src: Image2, title: 'Contraption v1.5' },
  { src: Image3, title: 'Contraption v1' },
];

function Project1() {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleNextImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };
  
    const handlePrevImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };
  return (
    <div>
      <h2>Contraption v2</h2>
      <div style={{ overflowX: 'auto', textAlign: 'left' }}>
      <Grid container spacing={2} justifyContent="left">
        {images.map((image, index) => (
          <Grid item key={index} style={{ display: index === currentImageIndex ? 'block' : 'none' }}>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                height="200"
                image={image.src}
                alt={image.title}
              />
              <Typography gutterBottom variant="h5" component="div" align="center">
                {image.title}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div style={{ marginTop: '10px' , marginLeft: '64px'}}>
        <Button variant="contained" color="primary" onClick={handlePrevImage} disabled={currentImageIndex === 0}>
          <NavigateBeforeIcon /> 
        </Button>
        <Button variant="contained" color="primary" onClick={handleNextImage} disabled={currentImageIndex === images.length - 1}>
           <NavigateNextIcon />
        </Button>
      </div>
      <p>The contraption is a hybrid controller combining the ergonomics of standard digital controllers with the intuitiveness of analog movement using a gamecube style controller.
        This project was iterated several times over several months with numerous firmware and hardware modifications related to integrating two seperate controller projects. Each version streamlined the design and added ergonomic features.
        The case, stand, and base were designed in openSCAD. Contraption v2 features modular design where each component holds a neodymium magnet and can be rotated and placed anywhere on the base plate.
      </p>
    </div>
    </div>
  );
}

export default Project1;