import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

function About() {
  const paperStyle = {
    padding: '16px',
    marginTop: '16px',
  };

  return (
    <Container maxWidth="md">
      <Paper style={paperStyle}>
        <Typography variant="h4" gutterBottom>
          About Me
        </Typography>
        <Typography variant="body1" paragraph>
          Hello. I'm Trilok (pronounced tree-loke), a web developer residing in the Greater Boston Area.
        </Typography>
        <Typography variant="body1" paragraph>
          I graduated with a dual major in Computer Engineering and Electrical Engineering from RPI and have been developing software for over 10 years.
        </Typography>
        <Typography variant="body1" paragraph>
          I wanted to create this website as a portfolio to showcase and archive my projects. My projects may be web development related, helpful programs, PCB designs, and even creating 3d cad models.
          Although I spend most of my time in software development, I do find engineering in general interesting from electric circuits, CAD, robotics, firmware, soldering, crafting, and any DIY related skills.
        </Typography>
        <Typography variant="body1" paragraph>
          My other hobbies include chess, hiking, running, and creating video-game controllers.
        </Typography>
      </Paper>
    </Container>
  );
}

export default About;